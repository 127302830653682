export const environment = {
    banorte: false,
    production: true,
    ente: "H. Ayuntamiento de Cosalá",
    administracion: "2021 - 2024",
    url_escudo: "../assets/cosala/escudo.png",
    url_administracion: "../assets/cosala/logo_Admins.png",
    url_footer: "../assets/cosala/logo_administracion.png",
    direcciones: [
        "Antonio Rosales S/N",
        "C.P. 80700",
        "Cosalá, Sinaloa"
    ],
    telefonos: [
        "698-893-03-20"
    ],
    emails: [
        "asuntos.cosala@hotmail.com",
        "Tesoreria@cosala.gob.mx"
    ],
    url_aviso: "https://www.cosala.gob.mx/",
    url_webapi: "https://pagoscosala.azurewebsites.net/api/",
    url_sitio: "https://pagoscosala.com.mx/",
    url_rpt: 'http://servidorsecomrpt.ddns.net:8081/api/',
    cve_municipio: "009",
    municipio: "COSALA",
    firebase: {
        apiKey: "AIzaSyAavk-puMnqkSgsG3VEsIqxQOEXaf3FDuA",
        authDomain: "pagoscosala.firebaseapp.com",
        projectId: "pagoscosala",
        storageBucket: "pagoscosala.appspot.com",
        messagingSenderId: "151945904175",
        appId: "1:151945904175:web:2f18a8f074b1bf4b0d86da",
        measurementId: "G-14WQ2XZKV2"
    },
    leyenda_fundamento: "Ley de ingresos del municipio de Cosalá para el año 2017. Artículos 1,2,5,7,8,9. Ley de" +
        "Hacienda Municipal del Estado de Sinaloa vigente para el año 2017 Artículos 30 al 37 y 105" +
        "Capítulo II. Ley deCatastro del Estado de Sinaloa Artículos 13,14 23 al 26 y 32 al 34. Ley" +
        "de Gobierno Municipal del Estado de Sinaloa Artículo 59 Fracción III y IV.",
    leyenda_recibo: "EN CASO DE REQUERIR FACTURA ELECTRONICA, SOLICITARLO EN UN PLAZO NO MAYOR A 3 DIAS DESPUES DEL MES EN QUE REALIZO EL PAGO",
    img_ayuda_1: '../assets/cosala/img_ayuda_1.jpg',
    img_ayuda_2: '../assets/cosala/img_ayuda_2.jpg',
    img_ayuda_3: '../assets/cosala/img_ayuda_3.jpg',
    id_express: "1234",
    llave: "-v0aeT5E1AtAelqxsjcG",
};
